<template>
    <CRow>
        <CCol>

            <WidgetsDropdownDashboard2/>
          
        </CCol>
     </CRow>
</template>


<script>
import WidgetsDropdownDashboard2 from './widgets/WidgetsDropdownDashboard2';

export default {
  name: 'Dashboard1',
  components: { WidgetsDropdownDashboard2 },
}
</script>
